import React from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import copper from "../../Assets/Images/copper.jpeg";
import steel from "../../Assets/Images/steel.jpeg";
import aliminum from "../../Assets/Images/aliminum.jpeg";
import rails from "../../Assets/Images/rails.jpeg";

function MetalProducts() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={copper} className="img-fluid" width="400"/>
                <h3 className="purple">COPPER ISOTOPE</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={steel} className="img-fluid" width="400"/>
                <h3 className="purple">STEEL</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={aliminum} className="img-fluid" width="400"/>
                <h3 className="purple">ALUMINIUM</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={rails} className="img-fluid" width="400"/>
                <h3 className="purple">USED RAILS</h3>
            </Tilt>
        </Col>
    </Row>
  );
}

export default MetalProducts;