import React from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import quartz from "../../Assets/Images/quartz.jpeg";
import lithium from "../../Assets/Images/lithium.jpeg";
import copper2 from "../../Assets/Images/aliminum.jpeg";

function MineralProducts() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={quartz} className="img-fluid" width="400"/>
                <h3 className="purple">QUARTZ</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={lithium} className="img-fluid" width="400"/>
                <h3 className="purple">LITHIUM</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={copper2} className="img-fluid" width="400"/>
                <h3 className="purple">COPPER</h3>
            </Tilt>
        </Col>
    </Row>
  );
}

export default MineralProducts;