import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/img6.png";
import Tilt from "react-parallax-tilt";

function Quote() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_frha6xu', 'template_5t82x2t', form.current, 'tMwhHlnEcWeVkvw3R')
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent!")
          alert("Quote request sent successfully.");
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            REQUEST A QUOTE: <span className="purple"> </span>
            </h1>
            <form ref={form} onSubmit={sendEmail} style={{textAlign: "left" }}>
              <br />
              <br />
              <label for="name">Name:</label>
              <br />
              <input type="text" id="name" name="name"></input>
              <br />
              <label for="email">Email:</label>
              <br />
              <input type="text" id="email" name="email"></input>
              <br />
              <br />
              <label for="transportation">Choose your desired mode of transportation:</label>
              <br />
              <select name="transportation" id="transportation">
                  <option value="">--Please choose an option--</option>
                  <option value="Dry Van">Dry Van</option>
                  <option value="Dry Van LTL">Dry Van LTL</option>
                  <option value="FlatBed TL">FlatBed TL</option>
                  <option value="FlatBed LTL">FlatBed LTL</option>
                  <option value="LTL">LTL</option>
                  <option value="LCL">LCL</option>
                  <option value="Reefer">Reefer</option>
                  <option value="Ocean Container">Ocean Container</option>
              </select>
              <br />
              <br />
              <i>
                <b className="purple"> - PICK UP LOCATION: </b>
              </i>
              <br />
              <br />
              <label for="Pick up Country">Country:</label>
              <br />
              <input type="text" id="Pick up Country" name="Pick up Country"></input>
              <br />
              <label for="Pick up Province/State">Province/State:</label>
              <br />
              <input type="text" id="Pick up Province/State" name="Pick up Province/State"></input>
              <br />
              <label for="Pick up City">City:</label>
              <br />
              <input type="text" id="Pick up City" name="Pick up City"></input>
              <br />
              <label for="Pick up Postal Code/ZIP">Postal Code/ZIP:</label>
              <br />
              <input type="text" id="Pick up Postal Code/ZIP" name="Pick up Postal Code/ZIP"></input>
              <br />
              <label for="Pick up Date">Pick up Date:</label>
              <br />
              <input type="text" id="Pick up Date" name="Pick up Date"></input>
              <br />
              <label for="Pick up Commodity">Commodity:</label>
              <br />
              <input type="text" id="Pick up Commodity" name="Pick up Commodity"></input>
              <br />
              <br />
              <i>
                <b className="purple"> - DELIVERY LOCATION: </b>
              </i>
              <br />
              <br />
              <label for="Delivery Country">Country:</label>
              <br />
              <input type="text" id="Delivery Country" name="Delivery Country"></input>
              <br />
              <label for="Delivery Province/State">Province/State:</label>
              <br />
              <input type="text" id="Delivery Province/State" name="Delivery Province/State"></input>
              <br />
              <label for="Delivery City">City:</label>
              <br />
              <input type="text" id="Delivery City" name="Delivery City"></input>
              <br />
              <label for="Delivery Postal Code/ZIP">Postal Code/ZIP:</label>
              <br />
              <input type="text" id="Delivery Postal Code/ZIP" name="Delivery Postal Code/ZIP"></input>
              <br />
              <label for="Delivery Date">Delivery Date:</label>
              <br />
              <input type="text" id="Delivery Date" name="Delivery Date"></input>
              <br />
              <label for="Delivery Commodity">Commodity:</label>
              <br />
              <input type="text" id="Delivery Commodity" name="Delivery Commodity"></input>
              <br />
              <br />
              <input type="submit" value="Submit"></input>
              
            </form>
            <p className="home-about-body">
            Our authentic relationships throughout North America allows us to provide quotes within the hour of
            your request during operating times.
            </p>
            <br />
            <p className="home-about-body">
            Call for a quote and more information: <b className="purple"> (647)-448-6724</b>
            <br />
            Email for a quote and more information: <b className="purple"> DISPATCH@REDEXEXPRESS.COM </b>
            </p>
            <br />
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" width="1000" height="800" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Quote;
