import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/im2.JPG";
import logo from "../../Assets/logo.png";
import mtm from "../../Assets/Images/MTM.jpeg";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import FoodProducts from "./FoodProducts";
import MetalProducts from "./MetalProducts";
import MineralProducts from "./MineralProducts";
import OilProducts from "./OilProducts";

function Commodities() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <br />
        <br />
        <br />
        <h1 className="project-heading">
          <strong className="purple">Redex Express partnered with MTM World to provide natural resources to their customers  throughout the globe: </strong>
          <a href="https://mtmworld.ca/">MTM WORLD INC</a>
        </h1>
        <img src={mtm} className="img-fluid" width="200"/>
        <h1 className="project-heading">
          <strong className="purple">Agro & Food Products: </strong>
        </h1>
        <FoodProducts />
        <h1 className="project-heading">
          <strong className="purple">Metal Products: </strong>
        </h1>
        <MetalProducts />
        <h1 className="project-heading">
          <strong className="purple">Mines & Minerals Products: </strong>
        </h1>
        <MineralProducts />
        <h1 className="project-heading">
          <strong className="purple">Oil & Natural Gas Products: </strong>
        </h1>
        <OilProducts />
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://ca.linkedin.com/in/redex-express-6800ab254"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/redex_express_/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Commodities;
