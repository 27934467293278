import React from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import gas from "../../Assets/Images/gas.jpeg";
import petcoke from "../../Assets/Images/petcoke.jpeg";
import diesel from "../../Assets/Images/diesel.jpeg";
import jet from "../../Assets/Images/jet.jpeg";

function OilProducts() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={gas} className="img-fluid" width="400"/>
                <h3 className="purple">GASOLINE</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={petcoke} className="img-fluid" width="400"/>
                <h3 className="purple">PETCOKE (Standard & Low Sulphur)</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={diesel} className="img-fluid" width="400"/>
                <h3 className="purple">EN590 10PPM DIESEL OIL</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={jet} className="img-fluid" width="400"/>
                <h3 className="purple">JET FUEL+ OTHERS FUELS</h3>
            </Tilt>
        </Col>
    </Row>
  );
}

export default OilProducts;