import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/im2.JPG";
import logo from "../../Assets/logo.png";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function About() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
              <Tilt>
              <img src={logo} className="img-fluid" width="400"/>
            </Tilt>
            <p className="home-about-body" >
              <i>
                <b className="purple"> Mission: </b>
              </i>
              Our prime objective is to provide the most effective cost-efficient supply chain experience to our customers. Our mission is to meet our customers requirements and demands to make their daily logistics experiences easier; allowing our customers to focus more on future shipments and prepare ahead.
              <br />
              <br />
              <i>
                <b className="purple"> Vision: </b>
              </i>
              With direct communication and real relationships our goal is to be the most reliant cost-efficient Freight Forwarder in North America.              <br />
              <br />
              <i>
                <b className="purple"> People: </b>
              </i>
              Our people at Redex Express have over 50 years of experience of moving freight throughout North America. Our team is based of individuals who have genuine relationships with our customers that creates a communicative community that allows us to help our customers always know about the status of their freight.              <br />
              <br />
              <i>
                <b className="purple"> Safety: </b>
              </i>
              In order to meet the safety compliance of our customers we provide the most suitable equitment and drivers with expertise to move your products safely. Our customers have special recommendations to be met once our carriers arrive for pickups, over the road transportation, and then deliveries to the destination always in a timely matter.
Our team is dedicated to meet all needs of our customers needs when it comes to the safety of there products.
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" style={{ position:"relative" , top: "180px" }} />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://ca.linkedin.com/in/redex-express-6800ab254"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/redex_express_/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default About;
