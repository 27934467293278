import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Routes, Route, useNavigate} from 'react-router-dom';
import myImg from "../../Assets/img1.png";
import Tilt from "react-parallax-tilt";

function FreightServices() {
  const navigate = useNavigate();
  const navigateToQuote = () => {
    navigate('/quote');
  };

  return (
    <Container fluid className="home-about-section" id="about" >
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              FREIGHT SERVICES: <span className="purple"> </span>
            </h1>
            <p className="home-about-body">
              <i>
                <b className="purple"> - CONSOLIDATION (LTL) DRY VANS/ FLATBEDS/ TEMPERATURE CONTROLLED / OCEAN CONTAINERS </b>
              </i>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>DRY VAN</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>DRY VAN LTL</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>FLATBED TL</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>FLATBED LTL</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>INTERMODAL</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>OCEAN SHIPPING FCL/LCL</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>PORT SERVICES</button>
              <br />
              <br />
              <button className="purple" onClick={navigateToQuote}>REEFER</button>
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default FreightServices;
