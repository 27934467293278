import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/img5.png";
import Tilt from "react-parallax-tilt";

function Communication() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            COMMUNICATION: <span className="purple"> </span>
            </h1>
            <p className="home-about-body">
              <br />
              <br />
              Communication is the main ingredient to any successful relationship. 
              <br />
              <br />
              We have built/gained the trust of our customers and carriers to differentiate from our competitors with simple communication of updates before and during transit times. Our competitors have the tendency to accept freight, find the lowest rate and hope for the best without some sort of communication; this is where we differ. With our effective communication we allow our customers to prepare and plan loads leaving no surprises. Our communication is promised to all parties involved of the movement of goods before and during transit. Our philosophy of communication has opened doors to new opportunities for our customers and strengthening our relationship between each other. We take pride in our communication to strengthen and grow with our customers and carriers. 
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Communication;
