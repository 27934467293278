import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/im3.JPG";
import myImg2 from "../../Assets/logo.png";
import Tilt from "react-parallax-tilt";

function Carrier() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_frha6xu', 'template_qnnhb4f', form.current, 'tMwhHlnEcWeVkvw3R')
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent!")
          alert("Carrier request sent successfully.");
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            CARRIERS/OUR TEAM: <span className="purple"> </span>
            </h1>
            <p className="home-about-body">
            <i>
                <b className="purple"> JOIN THE REDEX TEAM: </b>
              </i>
              <br />
              <br />
              <i>
                <b className="purple"> - APPLY TO BE A PARTNER CARRIER: </b>
              </i>
            </p>
            <form ref={form} onSubmit={sendEmail} style={{textAlign: "left" }}>
              <label for="company">Company:</label>
              <br />
              <input type="text" id="company" name="company"></input>
              <br />
              <br />
              <label for="firstname">First Name*:</label>
              <br />
              <input type="text" id="firstname" name="firstname"></input>
              <br />
              <br />
              <label for="lastname">Last Name*:</label>
              <br />
              <input type="text" id="lastname" name="lastname"></input>
              <br />
              <br />
              <label for="email">Email*:</label>
              <br />
              <input type="text" id="email" name="email"></input>
              <br />
              <br />
              <label for="website">Website:</label>
              <br />
              <input type="text" id="website" name="website"></input>
              <br />
              <br />
              <label for="phone">Phone*:</label>
              <br />
              <input type="text" id="phone" name="phone"></input>
              <br />
              <br />
              <label for="mc/dot">MC/DOT:</label>
              <br />
              <input id="mc/dot" name="mc/dot"></input>
              <br />
              <br />
              <input type="submit" value="Submit"></input>
            </form>
            <p className="home-about-body">
              <p className="purple">Please send proof of insurance to: <b className="purple"><i>DISPATCH@REDEXEXPRESS.COM</i></b>.</p>
              <br />
              <br />
              <i>
                <b className="purple"> - CARRIER SUPPORT: </b>
              </i>
              <br />
              <br />
              <p className="purple">Contact us at <b className="purple"><i>DISPATCH@REDEXEXPRESS.COM</i></b>.</p>
              <br />
              <br />
              <i>
                <b className="purple"> - DEDICATED FLEET / CONTRACTS: </b>
              </i>
              <br />
              <br />
              <p className="purple">Redex Express offers contracts that dedicate frieight to carriers that are under contract for our year round customers.</p>
              <br />
              <br />
              <i>
                <b className="purple"> - PAYMENTS: </b>
              </i>
              <br />
              <br />
              <p className="purple">EFT / CHEQUES / PAYMENTS TERMS – NET 30 DAYS</p>
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" width="800" height="800" />
            </Tilt>
            <Tilt>
              <img src={myImg2} className="img-fluid" width="800" height="800" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Carrier;
