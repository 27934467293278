import React from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import rice from "../../Assets/Images/rice.jpeg";
import oil from "../../Assets/Images/oil.jpeg";
import oil2 from "../../Assets/Images/oil2.jpeg";
import oil3 from "../../Assets/Images/oil3.jpeg";
import soy from "../../Assets/Images/soy.jpeg";
import wheat from "../../Assets/Images/wheat.jpeg";
import wheat2 from "../../Assets/Images/wheat2.jpeg";
import chicken from "../../Assets/Images/chicken.jpeg";
import sugar from "../../Assets/Images/sugar.jpeg";

function FoodProducts() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={rice} className="img-fluid" width="400"/>
                <h3 className="purple">RICE / BASMATI RICE</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={oil} className="img-fluid" width="400"/>
                <h3 className="purple">CRUDE REFINED SUNFLOWER OIL</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={oil2} className="img-fluid" width="400"/>
                <h3 className="purple">CRUDE RAPESEED OIL</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={oil3} className="img-fluid" width="400"/>
                <h3 className="purple">OLIVE OIL</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={soy} className="img-fluid" width="400"/>
                <h3 className="purple">SOY BEANS GMO/ NON GMO</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={wheat} className="img-fluid" width="400"/>
                <h3 className="purple">DURUM WHEAT</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={wheat2} className="img-fluid" width="400"/>
                <h3 className="purple">NON GMO SOFT MILLING WHEAT</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={chicken} className="img-fluid" width="400"/>
                <h3 className="purple">FROZEN CHICKEN WINGS /LEGS</h3>
            </Tilt>
        </Col>
        <Col xs={4} md={3} className="tech-icons">
            <Tilt>
                <img src={sugar} className="img-fluid" width="400"/>
                <h3 className="purple">BRAZILIAN SUGAR</h3>
            </Tilt>
        </Col>
    </Row>
  );
}

export default FoodProducts;